<template>
  <main class="home" role="main">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <div class="tbl">
        <div class="tbl__header">
          <div class="tbl__filter">
            <button v-if="selectedSendEdoDocs.length" class="btn btn--green" style="margin-right: 10px" @click="signAnyAndSend">Подписать</button>
            <button v-if="selectedSendEdoDocs.length" class="btn btn--red" @click="closeEdoMessage">Отклонить</button>
          </div>
          <div class="tbl__settings">
            <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
              <a class="tbl__setting" href="#" @click.prevent="switchColumnModal">
                <SettingsIcon/>
              </a>
            </mq-layout>
          </div>
        </div>
        <template>
          <ApolloQuery
            :key="refreshKey"
            :query="require('@/graphql/queries/DiadocDocuments.gql')"
            :variables="{
              refreshKey,
            }"
          >
            <template slot-scope="{ result: { error, data }, isLoading }">
              <div v-if="error">
                <input type="text" :value="error.message" id="message" disabled />
              </div>
              <vue-good-table
                v-if="data && data.diadocDocuments"
                :columns="$store.state.data.edo.columns"
                :rows="[...data.diadocDocuments.documents, ...data.sbisDocuments.documents] || []"
                styleClass="vgt-table"
                compactMode
              >
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field === 'sign'">
                    <input type="checkbox" v-model="selectedSendEdoDocs" :id="props.row.id" :value="props.row">
                    <!-- <div v-if="props.row.operator === 'Диадок'" class="btn" @click="signAndSend(props.row.messageId, props.row.files[0].id)">Подписать</div>
                    <div v-else-if="props.row.operator === 'СБИС'" class="btn" @click="sbisSignAndSend(props.row.messageId, props.row.files[0].id, props.row.documentId, props.row.id, props.row.contract.partner.inn, props.row.kpp, props.row.toTitle, props.row.files[0].filename)">Подписать</div> -->
                  </div>
                  <div v-if="props.column.field === 'reject'"><div class="btn" @click="sbisSignAndSend(props.row.messageId, props.row.files[0].id, props.row.documentId)">ТЕСТ</div></div>
                  <div v-if="props.column.field === 'responsible'">{{ props.row.responsible && props.row.responsible.name || '-' }}</div>
                  <div v-if="props.column.field === 'signer'">{{ props.row.signer && props.row.signer.name || '-' }}</div>
                  <div v-if="props.column.field === 'operator'">{{ props.row.operator || '-' }}</div>
                  <div v-if="props.column.field === 'view'">{{ props.row.document_view || '-' }}</div>
                  <div v-if="props.column.field === 'contract' && props.row.contract"><router-link :to="{ name: 'contract', params: { id: props.row.contract.id } }">{{ props.row.contract && props.row.contract.name || '-' }}</router-link></div>
                  <div v-if="props.column.field === 'contract' && !props.row.contract">-</div>
                  <div v-if="props.column.field === 'toTitle' && props.row.operator === 'СБИС'">{{ props.row.toTitle || '-' }}</div>
                  <div v-if="props.column.field === 'toTitle' && props.row.operator === 'Диадок'">{{ props.row.toTitle || '-' }}</div>
                  <div v-if="props.column.field === 'files' && props.row.operator === 'Диадок'">
                    <div v-for="file in props.row.files" :key="file.id"><a href="#" @click="downloadFile(props.row.messageId, file.id, file.filename)">{{ file.filename || '-' }}</a></div>
                  </div>
                  <div v-if="props.column.field === 'files' && props.row.operator === 'СБИС'">
                    <div v-for="file in props.row.files" :key="file.id"><a :href="`${baseURL}/file?id=${props.row.documentId}`">{{ file.filename || '-' }}</a></div>
                  </div>
                  <div v-if="props.column.field === 'fromTitle'">{{ props.row.fromTitle }}</div>
                  <div v-if="props.column.field === 'date' && props.row.operator === 'Диадок'">{{ new Date((props.row.date - 621355968000000000) / 10000).toLocaleString() || '-' }}</div>
                  <div v-if="props.column.field === 'date' && props.row.operator === 'СБИС'">{{ new Date(props.row.date).toLocaleString() || '-' }}</div>
                </template>
              </vue-good-table>
              <template slot="emptystate">
                <div v-if="isLoading" class="messages__status">Идёт загрузка данных...</div>
                <div v-else>Данных нет</div>
              </template>
            </template>
          </ApolloQuery>
        </template>
      </div>
      <div class="main__header">
        <div class="main__header__item left"></div>
        <div class="main__header__item">
          <h1>Маршруты</h1>
        </div>
        <div class="main__header__item right"></div>
      </div>
      <div class="main__content">
        <div class="tbl">
          <template>
            <ApolloQuery
              :key="refreshKey"
              :query="require('@/graphql/queries/EdoRoutes.gql')"
              :variables="{
                refreshKey,
              }"
            >
              <template slot-scope="{ result: { error, data } }">
                <div v-if="error">
                  <input type="text" :value="error.message" id="message" disabled />
                </div>
                <vue-good-table
                  v-if="data"
                  ref="edoRouteTable"
                  styleClass="vgt-table"
                  :line-numbers="true"
                  :columns="routeColumns"
                  :rows="transformEdoRows(data.edoRoutes)"
                  :group-options="{
                    enabled: true,
                    headerPosition: 'top',
                    collapsable: true
                  }"
                  compactMode
                  style="width: 100%; height: 100%; overflow-y: auto"
                >
                  <template slot="table-header-row" slot-scope="props">
                    <span style="color: black; cursor: pointer">
                      <div style="display: inline-block; width: 230px; padding: 0 15px">
                        <span>{{ props.row.label.date }}</span>
                      </div>
                      <span>{{ props.row.label.name }}</span>
                      <span> {{ props.row.label.creator_name }} </span>
                      <span> {{ '->' }} </span>
                      <span> {{ props.row.label.responsible_name }} </span>
                    </span>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'company'">{{ props.row.to_name }}</div>
                    <div v-if="props.column.field === 'operator'">{{ statusTransform(props.row.operator) }}</div>
                    <div v-if="props.column.field === 'status' && props.row.status && props.row.status !== ''">{{ props.row.status }}</div>
                    <div v-if="props.column.field === 'status' && (!props.row.status || props.row.status === '')">
                      <span
                        :class="{ 'tag': document.status }"
                        href="#"
                        v-for="(document) in props.row.documents"
                        :key="document.id"
                      >
                        {{ document.status }}
                      </span>
                    </div>
                    <div
                      v-if="props.column.field === 'documents' && props.row.operator === 'diadoc'"
                      class="edo-route__documents"
                    >
                      <a
                        href="#"
                        v-for="(document) in props.row.documents"
                        @click="downloadFile(props.row.message_id, document.document_id, document.filename)"
                        :key="document.id"
                      >
                        {{ document.filename }}
                      </a>
                    </div>
                    <div
                      v-if="props.column.field === 'documents' && props.row.operator === 'sbis'"
                      class="edo-route__documents"
                    >
                      <a
                        :href="`${baseURL}/file?id=${document.document_id}`"
                        v-for="(document) in props.row.documents"
                        :key="document.id"
                      >
                        {{ document.filename }}
                      </a>
                    </div>
                  </template>
                </vue-good-table>
              </template>
            </ApolloQuery>
          </template>
        </div>
      </div>
      <div class="main__header">
        <div class="main__header__item left"></div>
        <div class="main__header__item">
          <h1>Подписанные документы</h1>
        </div>
        <div class="main__header__item right"></div>
      </div>
      <div class="main__content">
        <div class="tbl">
          <div class="tbl__header">
            <div class="tbl__filter"></div>
            <div class="tbl__settings">
              <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
                <a class="tbl__setting" href="#" @click.prevent="switchSignedColumnModal">
                  <SettingsIcon/>
                </a>
              </mq-layout>
            </div>
          </div>
          <template>
            <ApolloQuery
              :key="refreshKey"
              :query="require('@/graphql/queries/CounteragentSignedDocuments.gql')"
              :variables="{
                refreshKey,
              }"
            >
              <template slot-scope="{ result: { error, data } }">
                <div v-if="error">
                  <input type="text" :value="error.message" id="message" disabled />
                </div>
                <vue-good-table
                  v-if="data"
                  :columns="$store.state.data.edo.signedColumns"
                  :rows="dateSort([...data.counteragentSignedDiadocDocuments, ...data.counteragentSignedSbisDocuments]) || []"
                  styleClass="vgt-table"
                  compactMode
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'date'">{{ new Date(props.row.date).toLocaleString() || ' ' }}</div>
                    <div v-if="props.column.field === 'companyName'">{{ props.row.companyName || ' ' }}</div>
                    <div v-if="props.column.field === 'operator'">{{ props.row.operator || ' ' }}</div>
                    <div v-if="props.column.field === 'status'">{{ props.row.status || ' ' }}</div>
                    <div v-if="props.column.field === 'attachments' && props.row.operator === 'Диадок'">
                      <div v-for="file in props.row.attachments" :key="file.id"><a href="#" @click="downloadDiadocSignedFile(file.downloadLink, file.filename)">{{ file.filename || ' ' }}</a></div>
                    </div>
                    <div v-if="props.column.field === 'attachments' && props.row.operator === 'СБИС'">
                      <div v-for="file in props.row.attachments" :key="file.id"><a target="_blank" @click="downloadSbisSignedFile(file.downloadLink, file.filename)">{{ file.filename || ' ' }}</a></div>
                    </div>
                  </template>
                  <div slot="emptystate">
                    <mq-layout v-if="$apollo.loading" :mq="['lg', 'xlg', 'xl']">
                      <div
                        v-for="row in $store.state.me.role === 'manager' ? 1 : 5" :key="row + 100"
                        style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; height: 70px"
                      >
                        <div class="sceleton" v-for="(block, index) in $store.state.data.edo.signedColumns.filter(elem => !elem.hidden).length" :key="index">
                          <span style="opacity: 0">{{ $store.state.data.edo.signedColumns.filter(elem => !elem.hidden)[index].label }}</span>
                        </div>
                      </div>
                    </mq-layout>
                    <div v-else>Данных нет</div>
                    <mq-layout :mq="['sm', 'xsm']">
                      <div v-if="$apollo.loading" class="messages__status">Идёт загрузка данных...</div>
                      <div v-else>Данных нет</div>
                    </mq-layout>
                  </div>
                </vue-good-table>
              </template>
            </ApolloQuery>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import SettingsIcon from '@/components/svg/settings'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import CLOSE_SBIS from '@/graphql/mutations/CloseSbisMessage.gql'
import { getUserCertificates, createAttachedSignature } from 'crypto-pro' // Certificate

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'edo',
  components: {
    SettingsIcon
  },
  data () {
    return {
      baseURL: process.env.VUE_APP_HTTP,
      refreshKey: 0,
      selectedSendEdoDocs: [],
      title: 'ЭДО',
      filterDropName: [],
      filterDropGroup: [],
      filter: {
        responsible_user_id: null,
        group_id: null
      },
      routeColumns: [
        { label: 'Получатель', field: 'company' },
        { label: 'Оператор', field: 'operator' },
        { label: 'Статус', field: 'status' },
        { label: 'Документы', field: 'documents' }
      ],
      columns: [
        { label: '', field: 'sign', hidden: false },
        // { label: '', field: 'reject', hidden: false },
        { label: 'Ответственный', field: 'responsible', hidden: false },
        { label: 'Подписант', field: 'signer', hidden: false },
        { label: 'Получатель', field: 'toTitle', hidden: false },
        { label: 'Договор', field: 'contract', hidden: false },
        { label: 'Оператор', field: 'operator', hidden: false },
        { label: 'Файлы', field: 'files', hidden: false },
        { label: 'Автор', field: 'fromTitle', hidden: false },
        { label: 'Дата', field: 'date', hidden: false }
      ],
      period: [
        new Date(2019, 0, 1),
        new Date(Date.now())
      ],
      callCenter: false,
      internet: false,
      openPeriod: false,
      reportData: {},
      sortFilter: {},
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      }
    }
  },
  methods: {
    statusTransform (status) {
      const statusObj = {
        diadoc: 'Диадок',
        sbis: 'СБИС'
      }
      return statusObj[status] || ''
    },
    transformEdoRows (rows) {
      const result = rows
        .map(row => ({
          mode: 'span',
          html: false,
          label: {
            date: new Date(row.createdAt).toLocaleString('ru-RU'),
            name: row.contract.name,
            creator_name: row.creator.name,
            responsible_name: row.responsible.name
          },
          children: row.waypoints
        }))
      return result
    },
    async signAnyAndSend () {
      this.selectedSendEdoDocs.map(edoDocument => {
        if (edoDocument.operator === 'Диадок') {
          return this.signAndSend(edoDocument.messageId, edoDocument.id)
        }
        if (edoDocument.operator === 'Сбис' || edoDocument.operator === 'СБИС') {
          return this.sbisSignAndSend(
            edoDocument.messageId,
            edoDocument.files[0].id,
            edoDocument.documentId,
            edoDocument.id,
            edoDocument.contract.partner.inn,
            edoDocument.kpp,
            edoDocument.toTitle,
            edoDocument.files[0].filename
          )
        }
      })
    },
    dateSort (arr) {
      return arr.sort((a, b) => {
        return new Date(b.date).getDate() - new Date(a.date).getDate()
      })
    },
    switchColumnModal () {
      this.$store.state.data.edo.columnModal = !this.$store.state.data.edo.columnModal
    },
    switchSignedColumnModal () {
      this.$store.state.data.edo.signedColumnModal = !this.$store.state.data.edo.signedColumnModal
    },
    arrayToBase64String (a) {
      return btoa(String.fromCharCode(...a))
    },
    async downloadSbisFile (documentId, filename) {
      const baseUrl = process.env.VUE_APP_HTTP
      await axios.get(`${baseUrl}/file?id=${documentId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })
        .then(async response => {
          const fileUrl = await this.b64toUrl(Buffer.from(response.data, 'base64'))
          let link = document.createElement('a')
          link.href = fileUrl
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          this.$notify({
            group: 'lsg-notify',
            text: error
          })
        })
    },
    async closeEdoMessage () {
      this.selectedSendEdoDocs.map(async edoDocument => {
        await this.$apollo.mutate({
          mutation: CLOSE_SBIS,
          variables: {
            id: edoDocument.id
          },
          update: (_, { data: { closeSbisMessage } }) => {
            if (!closeSbisMessage) {
              this.$notify({
                group: 'lsg-notify',
                duration: 1000,
                text: 'Ошибка. Не удалось закрыть документ на подписание'
              })
            }
            this.sendingData = false
          }
        }).catch(error => {
          this.sendingData = false
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
        this.refreshKey++
      })
    },
    async sbisSignAndSend (messageId, entityId, documentId, sbisMessageId, inn, kpp, counteragent, filename) {
      const baseUrl = process.env.VUE_APP_HTTP

      const file = await axios.get(`${baseUrl}/file?id=${documentId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })

      const certificates = await getUserCertificates()
        .catch(() => {
          const errorMessage = 'Ошибка Крипто Про. Не удаётся найти сертификаты'
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: errorMessage
          })
          throw new Error(errorMessage)
        })

      let thumbprint = 'BA5AE76AD8EA31BB24AB5C398930EDFAEBF9279E'

      if (this.$store.state.me.roles.includes('Руководитель подразделения (Лизинг)')) {
        const certificate = certificates.find(elem => {
          return elem.name === this.$store.state.me.name
        })
        if (certificate) thumbprint = certificate.thumbprint
      }

      const signature = await createAttachedSignature(thumbprint, file.data)
        .catch(() => {
          const errorMessage = 'Ошибка Крипто Про. Не удалось подписать документ'
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: errorMessage
          })
          throw new Error(errorMessage)
        })

      await axios.post(`${baseUrl}/api/sbis`, {
        file: Buffer.from(file.data, 'arraybuffer').toString('base64'),
        signedFile: signature
      }, {
        params: {
          filename: filename,
          inn: inn,
          id: documentId,
          thumbprint: thumbprint,
          attachmentId: '',
          kpp: kpp,
          counteragent: counteragent
        },
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })
        .then(async () => {
          await this.$apollo.mutate({
            mutation: CLOSE_SBIS,
            variables: {
              id: sbisMessageId
            },
            update: (_, { data: { closeSbisMessage } }) => {
              if (!closeSbisMessage) {
                this.$notify({
                  group: 'lsg-notify',
                  duration: 1000,
                  text: 'Ошибка. Не удалось закрыть документ на подписание'
                })
              }
              this.sendingData = false
            }
          }).catch(error => {
            this.sendingData = false
            _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
              this.$notify({
                group: 'lsg-notify',
                text: item
              })
            })
          })
          this.refreshKey++
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: 'Документ подписан'
          })
        })
        .catch(() => {
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: 'Ошибка в подписании документа'
          })
        })
    },
    async signAndSend (messageId, entityId) {
      const baseUrl = process.env.VUE_APP_HTTP

      const file = await axios.get(`${baseUrl}/api/diadoc/file-entity?messageId=${messageId}&entityId=${entityId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })

      const certificates = await getUserCertificates()
        .catch(() => {
          const errorMessage = 'Ошибка Крипто Про. Не удаётся найти сертификаты'
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: errorMessage
          })
          throw new Error(errorMessage)
        })

      let thumbprint = 'BA5AE76AD8EA31BB24AB5C398930EDFAEBF9279E'

      if (this.$store.state.me.role === 'headmanager') {
        const certificate = certificates.find(elem => {
          return elem.name === this.$store.state.me.name
        })
        if (certificate) thumbprint = certificate.thumbprint
      }

      const signature = await createAttachedSignature(thumbprint, file.data)
        .catch(() => {
          const errorMessage = 'Ошибка Крипто Про. Не удалось подписать документ'
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: errorMessage
          })
          throw new Error(errorMessage)
        })

      await axios.post(`${baseUrl}/api/diadoc/sign`, {
        messageId: messageId,
        parentEntityId: entityId,
        signature: signature
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })
        .then(async () => {
          this.refreshKey++
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: 'Документ подписан'
          })
          await this.$apollo.mutate({
            mutation: CLOSE_SBIS,
            variables: {
              id: messageId
            },
            update: (_, { data: { closeSbisMessage } }) => {
              if (!closeSbisMessage) {
                this.$notify({
                  group: 'lsg-notify',
                  duration: 1000,
                  text: 'Ошибка. Не удалось закрыть документ на подписание'
                })
              }
              this.sendingData = false
            }
          }).catch(error => {
            this.sendingData = false
            _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
              this.$notify({
                group: 'lsg-notify',
                text: item
              })
            })
          })
        })
        .catch(() => {
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: 'Ошибка в подписании документа'
          })
        })
    },
    async b64toUrl (base64Data) {
      const r = await fetch('data:application/pdf;base64,' + base64Data)
      const blob = await r.blob()
      return URL.createObjectURL(blob)
    },
    async downloadFile (messageId, entityId, filename) {
      const baseUrl = process.env.VUE_APP_HTTP
      await axios.get(`${baseUrl}/api/diadoc/file?messageId=${messageId}&entityId=${entityId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })
        .then(async response => {
          const fileUrl = await this.b64toUrl(Buffer.from(response.data, 'base64'))
          let link = document.createElement('a')
          link.href = fileUrl
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          this.$notify({
            group: 'lsg-notify',
            text: error
          })
        })
    },
    async downloadSbisSignedFile (path, filename, renderIndex = 1) {
      if (renderIndex > 2) {
        window.open('https://sso.sbis.ru/auth-online/?ret=/auth-online')
        return
      }
      const sbisFileWindow = window.open(path)
      setTimeout(() => {
        if (!sbisFileWindow.closed) {
          sbisFileWindow.close()
          this.downloadSbisSignedFile(path, filename, renderIndex + 1)
        }
      }, 1_000)
    },
    async downloadDiadocSignedFile (path, filename) {
      const baseUrl = process.env.VUE_APP_HTTP
      await axios.get(`${baseUrl}${path}`, {
        responseType: 'blob',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })
        .then(async response => {
          const fileUrl = URL.createObjectURL(response.data)
          let link = document.createElement('a')
          link.href = fileUrl
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          this.$notify({
            group: 'lsg-notify',
            text: error
          })
        })
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })
    },
    onUserChange (e) {
      if (e) {
        this.filter.responsible_user_id = e.value
      } else {
        this.filter.responsible_user_id = null
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .sceleton
    position: relative
    display: inline-block
    height: 40px
    margin: 0 10.5px 5px
    border-radius: 10px
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%
    animation: loading 1.2s ease-in-out infinite;

  .edo-route__documents
    display: flex
    flex-direction column
</style>
